import { keys } from "lodash";

function getKeyValueRecursive(objekt, key) {
  if (!objekt) {
    return undefined;
  }

  if (!!objekt[key]) {
    return objekt[key];
  }

  if (typeof objekt === "object" || typeof objekt === "array") {
    const objektKeys = keys(objekt);
    for (let index = 0; index < objektKeys.length; index++) {
      const value = objekt[objektKeys[index]];
      const retrievedValue = getKeyValueRecursive(value, key);
      return retrievedValue;
    }
  }

  return undefined;
}

export { getKeyValueRecursive };
