<script>
import {
  cleanPhoneNumber,
  phoneNumberValidFull,
  phoneNumberValidPartial,
} from "@/utils/phone";
import { getKeyValueRecursive } from "@/utils/objects";
import sha256 from "crypto-js/sha256";
import { debounce, capitalize, pick } from "lodash";
import {
  UPDATE_CUSTOMER,
  CREATE_CUSTOMER,
} from "@/graphql/customer/mutations.gql";
import { UPDATE_CHECKIN_REQUEST, CREATE_CHECKIN_REQUEST } from "./graphql";
import { SEARCH_CUSTOMERS_BY_LICENSE_HASH } from "@/graphql/customer/queries.gql";
import { SEARCH_CUSTOMERS } from "@/graphql/drs_customer/queries.gql";
import { usParse, caParse } from "@/utils/decode-id";
import VxDialogView from "@/components/vx/VxDialogView";
import DrsCustomersList from "./components/DrsCustomersList";
import { VxPhoneField } from "@/core-ui";
import { START_BUY_STATES } from "./StartBuyStates";
import { mapMutations } from "vuex";

export default {
  name: "StartBuyDialog",
  components: {
    VxDialogView,
    VxPhoneField,
    DrsCustomersList,
  },
  props: {
    initialLicenseData: { type: String, default: "" },
    storeId: { type: [String, Number], default: undefined },
    initialPhoneNumber: { type: [String], default: undefined },
    checkinRequestId: { type: [String, Number], default: undefined },
  },
  data: (wm) => {
    return {
      buyState: START_BUY_STATES.LICENSE_NOT_SCANNED,

      loadingDrsCustomers: false,
      drsCustomers: undefined,
      selectedDrsCustomer: undefined,

      searchByName: false,
      customerSearchName: undefined,

      scannerTooltip: {
        visible: false,
        source: undefined,
        hideTimeoutHandler: undefined,
      },

      loadingCustomers: false,
      // The scanned or new customer
      customer: {
        phoneNumber: wm.initialPhoneNumber,
        licenseData: undefined,
        licenceHash: undefined,
        isFoundCustomer: false,
        isLinked: false,
        isNewCustomer: false,
      },

      nextLoading: false,
      scanLoading: false,

      rules: {
        phoneValidPartial: (value) => {
          const cleanedPhoneNumber = cleanPhoneNumber(value);
          return (
            phoneNumberValidPartial(cleanedPhoneNumber) ||
            "Not a valid North America phone number."
          );
        },
      },
    };
  },
  computed: {
    isSmallScreen() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    isNewCustomerWithNoDrsCustomer() {
      return (
        this.drsCustomers !== null &&
        this.drsCustomers !== undefined &&
        this.drsCustomers.length <= 0 &&
        !this.selectedDrsCustomer &&
        phoneNumberValidFull(cleanPhoneNumber(this.customer.phoneNumber))
      );
    },
    isValidState() {
      if (!!this.loadingDrsCustomers) {
        return false;
      }

      switch (this.buyState) {
        case START_BUY_STATES.LICENSE_NOT_SCANNED:
          if (this.customer.isNewCustomer) {
            return phoneNumberValidFull(
              cleanPhoneNumber(this.customer.phoneNumber)
            );
          }
          if (this.isNewCustomerWithNoDrsCustomer) {
            return true;
          }
          return !!this.selectedDrsCustomer;
        case START_BUY_STATES.LICENSE_SCANNED:
          if (this.customer.isFoundCustomer && this.customer.isLinked) {
            return true;
          } else {
            if (this.customer.isNewCustomer) {
              return phoneNumberValidFull(
                cleanPhoneNumber(this.customer.phoneNumber)
              );
            }

            if (this.isNewCustomerWithNoDrsCustomer) {
              return true;
            }

            return !!this.selectedDrsCustomer;
          }
      }

      return false;
    },
    MIN_PHONE_LENGTH_TO_SEARCH() {
      return 5;
    },
    MIN_NAME_LENGTH_TO_SEARCH() {
      return 5;
    },
    showDrsCustomersList() {
      if (this.searchByName) {
        if (!this.customerSearchName) {
          return false;
        }

        if (this.customerSearchName.length < this.MIN_PHONE_LENGTH_TO_SEARCH) {
          return false;
        }
      } else {
        if (!this.customer.phoneNumber) {
          return false;
        }

        if (
          this.customer.phoneNumber.length < this.MIN_PHONE_LENGTH_TO_SEARCH
        ) {
          return false;
        }
      }

      if (this.customer.isNewCustomer) {
        return false;
      }

      return true;
    },
    showLicenceScanInstructions() {
      return this.buyState !== START_BUY_STATES.LICENSE_SCANNED;
    },
    showScannedLicenceInfo() {
      return (
        this.buyState === START_BUY_STATES.LICENSE_SCANNED && !!this.customer
      );
    },
    showLookupByPhoneNumberInfo() {
      return this.buyState === START_BUY_STATES.LICENSE_NOT_SCANNED;
    },
    phoneNumberPlaceholder() {
      if (
        this.buyState === START_BUY_STATES.LICENSE_SCANNED &&
        !this.customer.isFoundCustomer
      ) {
        return "Add phone number";
      }

      return "Enter phone number";
    },
    noCustomersListText() {
      if (phoneNumberValidFull(cleanPhoneNumber(this.customer.phoneNumber))) {
        return "No existing customers found. Click next to create new customer.";
      }

      return "No existing customers found";
    },
  },
  watch: {
    drsCustomers() {
      this.selectedDrsCustomer = undefined;
    },
    "customer.isNewCustomer"() {
      this.selectedDrsCustomer = undefined;
    },
    searchByName(newSearchByName) {
      if (newSearchByName) {
        setTimeout(() => this.$refs.customerNameField.focus(), 1);
        if (
          this.customerSearchName &&
          this.customerSearchName.length >= this.MIN_NAME_LENGTH_TO_SEARCH
        ) {
          this.getCustomersDebounced(this.customerSearchName);
        }
      } else {
        setTimeout(() => this.$refs.phoneField.focus(), 1);
        if (
          this.customer.phoneNumber &&
          this.customer.phoneNumber.length >= this.MIN_PHONE_LENGTH_TO_SEARCH
        ) {
          this.getCustomersDebounced(this.customer.phoneNumber);
        }
      }
    },
    customerSearchName(newNameToSearch) {
      if (
        newNameToSearch &&
        newNameToSearch.length >= this.MIN_NAME_LENGTH_TO_SEARCH
      ) {
        if (!this.drsCustomers) {
          this.drsCustomers = [];
        }

        this.loadingDrsCustomers = true;
        this.getCustomersDebounced(newNameToSearch);
      }
    },
    "customer.phoneNumber"(newPhoneNumber) {
      if (
        newPhoneNumber &&
        newPhoneNumber.length >= this.MIN_PHONE_LENGTH_TO_SEARCH
      ) {
        if (!this.drsCustomers) {
          this.drsCustomers = [];
        }

        this.loadingDrsCustomers = true;
        this.getCustomersDebounced(newPhoneNumber);
      } else {
        this.drsCustomers = undefined;
      }
    },
  },
  created: function () {
    this.mountScanListener();
  },
  beforeMount() {
    if (this.initialLicenseData) {
      this.handleBarcode({ detail: { data: this.initialLicenseData } });
    }
  },
  mounted() {
    setTimeout(() => {
      this.$refs.phoneField.focus();
    }, 1);

    if (this.customer.phoneNumber) {
      this.loadingDrsCustomers = true;
      this.getCustomersDebounced(this.customer.phoneNumber);
    }
  },
  destroyed: function () {
    this.dismountScanListener();
  },
  methods: {
    ...mapMutations("snackbar", ["showSnackbar"]),
    // Takes a scanned barcode (here, a driver's license) and
    // attempts a customer lookup.
    // Found is a boolean and only used for simulating DL scanning
    async handleScan({ detail: { parsed: v, raw } }) {
      try {
        this.customer.licenseData = v;

        const hash = sha256(JSON.stringify(v)).toString();
        this.customer.licenseHash = hash;

        this.buyState = START_BUY_STATES.LICENSE_SCANNED;

        this.loadingCustomers = true;
        const searchCustomersResponse = await this.$apollo.query({
          query: SEARCH_CUSTOMERS_BY_LICENSE_HASH,
          variables: {
            storeId: this.storeId,
            input: {
              q: hash,
            },
          },
          fetchPolicy: "network-only",
        });
        if (
          this.handleError(
            "Rails GraphQL error occurred searching customers",
            searchCustomersResponse
          )
        ) {
          return;
        }

        if (
          searchCustomersResponse.data.customers &&
          searchCustomersResponse.data.customers.length === 1
        ) {
          this.customer = Object.assign(
            {},
            this.customer,
            searchCustomersResponse.data.customers[0],
            {
              isFoundCustomer: true,
              isLinked: true,
              licenseHash: hash,
            }
          );
        } else {
          this.customer = Object.assign({}, this.customer, {
            isFoundCustomer: false,
            phoneNumber: undefined,
            isLinked: false,
          });
        }
      } finally {
        this.loadingCustomers = true;
      }
    },

    // Takes a scanned barcode and parses it to see if it is a license
    async handleBarcode({ detail: { data: v } }) {
      const leadingChar = v.charCodeAt(0);
      // Guard. If the barcode doesn't begin with either `@` or `%`, just return
      if ([64, 37].indexOf(leadingChar) < 0) return;

      let parsed;
      // If the first character is `@`, try to decode with usParse
      // If the first character is `%`, try to decode with caParse
      if (leadingChar === 64) {
        parsed = usParse(v);
      }
      if (leadingChar === 37) {
        parsed = caParse(v);
      }
      // If the return object has any errors, log them
      if (parsed.errors && parsed.errors.length) {
        try {
          const checksum = btoa(
            JSON.stringify({
              v,
              errors: parsed.errors,
            })
          );
          if (!!raiPos) {
            raiPos.writeLog(`checksum errors ${checksum}`);
          }
        } catch (error) {
          console.log("Error generating checksum", error);
        }
      }
      const compat = { detail: { parsed, raw: v } };
      // Pass the decoded result on to `handleScan`
      return this.handleScan(compat);
    },
    /**
     * Updates an existing customer on server side
     */
    updateCustomerLicenceData(customer) {
      const variables = {
        input: {
          id: customer.id,
          licenseHash: customer.licenseHash,
          licenseData:
            customer.licenseData === "string"
              ? customer.licenseData
              : JSON.stringify(customer.licenseData),
        },
      };

      return this.$apollo
        .mutate({
          mutation: UPDATE_CUSTOMER,
          variables: variables,
        })
        .catch((error) => {
          if (!this.handleError("Error updating customer", { errors: error })) {
            throw error;
          }
        });
    },
    /**
     * Creates a new customer on server side
     */
    createCustomer(customer) {
      const variables = {
        input: pick(customer, ["phoneNumber", "licenseData", "licenseHash"]),
      };

      if (
        !!variables.input.licenseData &&
        typeof variables.input.licenseData !== "string"
      ) {
        variables.input.licenseData = JSON.stringify(
          variables.input.licenseData
        );
      }

      return this.$apollo
        .mutate({
          mutation: CREATE_CUSTOMER,
          variables,
        })
        .catch((error) => {
          if (!this.handleError("Error creating customer", { errors: error })) {
            throw error;
          }
        });
    },
    createCheckinRequest(customer, phoneNumber, licenseData) {
      const variables = {
        input: {
          customerId: customer ? customer.id : null,
          customerData: {
            phoneNumber,
            licenseVerifiedAt: licenseData ? new Date().toJSON() : null,
          },
          sendText: true,
        },
      };

      return this.$apollo
        .mutate({
          mutation: CREATE_CHECKIN_REQUEST,
          variables,
        })
        .catch((error) => {
          if (
            !this.handleError("Error updating checkin request", {
              errors: error,
            })
          ) {
            throw error;
          }
        });
    },
    updateCheckinRequest(customer, licenseData) {
      const variables = {
        input: {
          id: this.checkinRequestId,
          customerId: customer ? customer.id : null,
          customerData: {
            // TODO - see if this needs to be here or also on whole input level ?
            licenseVerifiedAt: licenseData ? new Date().toJSON() : null,
          },
          sendText: true,
        },
      };

      return this.$apollo
        .mutate({
          mutation: UPDATE_CHECKIN_REQUEST,
          variables,
        })
        .catch((error) => {
          if (
            !this.handleError("Error updating checkin request", {
              errors: error,
            })
          ) {
            throw error;
          }
        });
    },
    mountScanListener() {
      window.addEventListener("onlicenseinput", this.handleScan);
      window.addEventListener("onbarcode", this.handleBarcode);
    },
    dismountScanListener() {
      window.removeEventListener("onlicenseinput", this.handleScan);
      window.removeEventListener("onbarcode", this.handleBarcode);
    },

    // TODO - move this funciton to somewhere common
    /** Handles response error. Returns true if there were errors and false if there were not errors */
    handleError(prefix, response) {
      // First find errors
      let errors = getKeyValueRecursive(response, "errors");

      if (!errors || !errors.length > 0) {
        return false;
      }

      if (Array.isArray(errors)) errors = errors.join("; ");
      console.error(errors);
      this.showSnackbar({
        text: `${prefix}: ${errors}`,
      });

      return true;
    },

    async handleNextClick() {
      if (!this.isValidState) {
        return;
      }

      if (!this.customer) {
        return;
      }
      try {
        let customerForBuy = undefined;
        this.nextLoading = true;
        // TODo - this should happen later!! maybe ?
        if (
          this.customer.isNewCustomer ||
          this.isNewCustomerWithNoDrsCustomer
        ) {
          const createCustomerResponse = await this.createCustomer(
            this.customer
          );
          if (
            this.handleError(
              "Rails GraphQL error occurred creating customer",
              createCustomerResponse
            )
          ) {
            return;
          }

          customerForBuy = createCustomerResponse.data.createCustomer.customer;
        }

        if (
          this.selectedDrsCustomer ||
          (this.customer.isFoundCustomer && this.customer.isLinked)
        ) {
          if (this.customer.licenseData) {
            const updateCustomerResponse = await this.updateCustomerLicenceData(
              this.customer
            );
            if (
              this.handleError(
                "Rails GraphQL error occurred updating customer",
                updateCustomerResponse
              )
            ) {
              return;
            }

            customerForBuy =
              updateCustomerResponse.data.updateCustomer.customer;
          } else {
            // Todo - check if this is ok, to get a customer from drs customer
            customerForBuy = this.selectedDrsCustomer.customer;
          }
        }

        if (!!customerForBuy) {
          let checkinCreateUpdateResponse;

          if (this.checkinRequestId) {
            checkinCreateUpdateResponse = await this.updateCheckinRequest(
              customerForBuy,
              this.customer.licenseData
            );
          } else {
            checkinCreateUpdateResponse = await this.createCheckinRequest(
              customerForBuy,
              this.customer.phoneNumber,
              this.customer.licenseData
            );
          }

          if (
            this.handleError(
              "Rails GraphQL error occurred creating buy",
              checkinCreateUpdateResponse
            )
          ) {
            return;
          }

          let newCheckinRequestId;
          if (this.checkinRequestId) {
            newCheckinRequestId =
              checkinCreateUpdateResponse.data.checkinRequestUpdate
                .checkinRequest.id;
          } else {
            newCheckinRequestId =
              checkinCreateUpdateResponse.data.checkinRequestCreate
                .checkinRequest.id;
          }

          this.$router.replace({
            name: "buys_edit_checkinrequset",
            params: {
              checkinRequestId: newCheckinRequestId,
            },
            preserveQuery: true,
          });
        }
      } finally {
        this.nextLoading = false;
      }
    },

    // TODo - have this as computed fullName prop
    capitalize: capitalize,
    getCustomersDebounced: debounce(function (newPhoneNumberOrName) {
      this.$apollo
        .query({
          query: SEARCH_CUSTOMERS,
          variables: {
            storeId: this.$route.params.storeId,
            input: {
              q: newPhoneNumberOrName,
            },
          },
        })
        .then((response) => {
          this.loadingDrsCustomers = false;
          if (response.data && response.data.customers) {
            this.drsCustomers = response.data.customers;
          } else {
            this.drsCustomers = [];
          }
        })
        .catch((error) => {
          this.loadingDrsCustomers = false;
          this.handleError("Error loading drs customers", { errors: error });
        });
    }, 500),
    persistScannerTooltip() {
      if (this.scannerTooltip.visible === true) {
        // If still visible, stop the possible hiding from mouse leaving the info icon
        if (!!this.scannerTooltip.hideTimeoutHandler) {
          clearTimeout(this.scannerTooltip.hideTimeoutHandler);
          this.scannerTooltip.hideTimeoutHandler = undefined;
        }
      }
    },
    showScannerTooltip(source) {
      if (this.scannerTooltip.visible !== true) {
        this.scannerTooltip.visible = true;
      }
      if (source === "click" || this.scannerTooltip.source !== "click") {
        this.scannerTooltip.source = source;
      }
    },
    hideScannerTooltip(source) {
      if (source === "mouseleave") {
        if (this.scannerTooltip.source === "mouseover") {
          this.scannerTooltip.hideTimeoutHandler = setTimeout(() => {
            this.scannerTooltip.visible = false;
            this.scannerTooltip.source = undefined;
          }, 300);
        }
      } else {
        this.scannerTooltip.visible = false;
        this.scannerTooltip.source = undefined;
      }
    },
    onClickOutsideScannerTooltip() {
      this.hideScannerTooltip("click");
    },
    onClickOutsideScannerTooltipInclude() {
      if (!this.$refs.tooltipText) {
        return [];
      }

      return [this.$refs.tooltipText];
    },
    onSelectedCustomerChange(drsCustomer) {
      this.selectedDrsCustomer = drsCustomer;
      if (!drsCustomer) {
        this.customer.id = undefined;
      } else {
        this.customer.id = this.selectedDrsCustomer.customer.id;
      }
    },
    onSearchToggle() {
      this.selectedDrsCustomer = false;
      this.customer.phoneNumber = "";
    },
  },
};
</script>

<template>
  <VxDialogView
    :title="'New buy'"
    :retain-focus="false"
    :h-size="'small'"
    :v-size="'large'"
  >
    <template v-if="!isSmallScreen" #actions>
      <v-btn
        text
        :color="'primary'"
        :disabled="!isValidState"
        :loading="nextLoading"
        @click="handleNextClick"
        v-text="`Next`"
      />
    </template>
    <template v-if="isSmallScreen" #large-actions>
      <v-btn
        text
        :color="'primary'"
        :disabled="!isValidState"
        :loading="nextLoading"
        @click="handleNextClick"
        v-text="`Next`"
      />
    </template>

    <template>
      <div
        v-if="showLicenceScanInstructions"
        class="text-h5 primary--text font-weight-medium"
      >
        Scan license to begin
        <v-tooltip
          v-model="scannerTooltip.visible"
          bottom
          :open-on-hover="false"
          content-class="scanner-tooltip"
        >
          <template #activator="{}">
            <v-icon
              ref="tooltipIcon"
              v-click-outside="{
                handler: onClickOutsideScannerTooltip,
                include: onClickOutsideScannerTooltipInclude,
              }"
              small
              :color="'grey'"
              @mouseover="showScannerTooltip('mouseover')"
              @mouseleave="hideScannerTooltip('mouseleave')"
              @click="showScannerTooltip('click')"
              >$info</v-icon
            >
          </template>
          <template>
            <div
              ref="tooltipText"
              @mouseover="persistScannerTooltip"
              @mouseleave="hideScannerTooltip('mouseleave')"
            >
              This requires a 2D scanner or Flex device.
              <a
                class="white--text"
                target="_blank"
                href="https://help.resaleai.com/support/solutions/articles/154000045041-what-are-the-benefits-of-the-pos-scanner-and-the-rai-flex-device-which-do-i-need-"
                >Learn more</a
              >
            </div>
          </template>
        </v-tooltip>
      </div>
      <div v-if="showScannedLicenceInfo">
        <!-- TODO - fix this with customer phone number and customer from licence in general !!! -->
        <div v-if="!customer.isFoundCustomer">
          <div class="d-flex">
            <v-icon color="primary" large>$vuetify.icons.checkCircle</v-icon>
            <span class="text-h5 primary--text font-weight-medium"
              >Licence saved for
              {{ capitalize(customer.licenseData.firstName) }}
              {{ capitalize(customer.licenseData.lastName) }}
            </span>
          </div>
        </div>
        <div v-if="customer.isFoundCustomer">
          <div class="d-flex">
            <v-icon color="primary" large>$vuetify.icons.checkCircle</v-icon>
            <span class="text-h5 primary--text font-weight-medium"
              >Welcome back, {{ capitalize(customer.licenseData.firstName) }}
            </span>
          </div>
          <div class="text-subtitle-1 mt-3">
            Is this phone number still correct ?
          </div>
        </div>
      </div>
      <div class="search-title my-4">
        <div v-if="showLookupByPhoneNumberInfo" class="text-h6">
          Or look up by
        </div>
        <v-btn-toggle
          v-model="searchByName"
          color="primary"
          dense
          mandatory
          group
          @change="onSearchToggle"
        >
          <v-btn :value="false">
            <span class="text-h6 text-lowercase">Phone</span>
          </v-btn>
          <v-divider class="mx-1" vertical />
          <v-btn :value="true">
            <span class="text-h6 text-lowercase">Name</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <VxPhoneField
        v-if="!searchByName"
        ref="phoneField"
        v-model="customer.phoneNumber"
        label="Phone number"
        :placeholder="phoneNumberPlaceholder"
        persistent-placeholder
        single-line
        rules="na_phone:partial"
      />
      <div v-if="customer.isFoundCustomer && customer.isLinked">
        <v-btn
          plain
          color="secondary"
          class="mb-4"
          @click="customer.isLinked = false"
        >
          Not {{ capitalize(customer.licenseData.firstName) }}
        </v-btn>
      </div>

      <div v-if="!customer.isLinked">
        <div v-if="searchByName && !customer.isNewCustomer">
          <v-text-field
            ref="customerNameField"
            v-model="customerSearchName"
            label="Search customers by name"
          />
        </div>
        <div v-if="showDrsCustomersList" class="link-to-customer-section">
          <div class="text-subtitle-1">Link to an existing customer</div>
          <DrsCustomersList
            class="mb-4"
            :drs-customers="drsCustomers"
            :loading="loadingDrsCustomers"
            :no-customers-text="noCustomersListText"
            @selectedCustomerChange="onSelectedCustomerChange"
          />
          <v-btn
            v-if="!customer.isNewCustomer"
            plain
            color="secondary"
            class="mb-4"
            @click="customer.isNewCustomer = true"
          >
            Create a new customer
          </v-btn>
        </div>
        <div v-if="customer.isNewCustomer">
          <div>
            Enter a valid phone and click next to create a new customer with
            specified phone number.
          </div>
          <v-btn
            plain
            color="secondary"
            class="mb-4"
            @click="customer.isNewCustomer = false"
          >
            Link to an existing customer
          </v-btn>
        </div>
      </div>
    </template>
  </VxDialogView>
</template>

<style lang="scss" scoped>
@import "@/sass/scrollbars.scss";

.scanner-tooltip {
  &.v-tooltip__content {
    pointer-events: unset;
  }
}

.link-to-customer-section {
  @include scrollbars(
    6px,
    map-get($ui, "lighten-2"),
    map-get($ui, "lighten-5")
  );
}

.theme--dark .link-to-customer-section::v-deep {
  @include scrollbars(6px, map-get($ui, "darken-1"), map-get($ui, "darken-5"));
}

.search-title {
  display: flex;
  align-items: center;
}
</style>
