<script>
import { get } from "lodash";

export default {
  name: "DrsCustomersList",
  components: {},
  props: {
    drsCustomers: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: { type: [Number, Boolean], default: false },
    noCustomersText: { type: String, default: "No existing customers found." },
  },
  data() {
    return { selectedCustomerIndex: undefined };
  },
  computed: {
    drsCustomersInternal() {
      return this.drsCustomers ? this.drsCustomers : [];
    },
  },
  watch: {
    drsCustomers() {
      this.selectedCustomerIndex = undefined;
    },
  },
  methods: {
    get: get,
    selectCustomer(customerIndex) {
      this.selectedCustomerIndex = customerIndex;
      this.$emit("selectedCustomerChange", this.drsCustomers[customerIndex]);
    },
  },
};
</script>

<template>
  <v-list
    outlined
    max-height="200"
    height="200"
    class="overflow-y-auto pt-0 pb-0"
  >
    <v-progress-linear
      v-show="!!loading"
      height="4"
      indeterminate
      class="ma-0"
    />
    <v-list-item v-if="!loading && drsCustomersInternal.length <= 0">
      {{ noCustomersText }}
    </v-list-item>
    <v-list-item-group
      v-else
      :value="selectedCustomerIndex"
      @change="selectCustomer"
    >
      <v-list-item v-for="customer in drsCustomersInternal" :key="customer.id">
        <v-list-item-content>
          <v-list-item-title
            :class="{ 'error--text': get(customer, 'customer.flagged') }"
          >
            {{ customer.fullName }}
          </v-list-item-title>
          <v-list-item-subtitle
            :class="{
              'd-flex': true,
              'flex-wrap': true,
              'error--text': get(customer, 'customer.flagged'),
            }"
          >
            <div class="mr-2">
              {{
                $filters.fullPhoneNumberNoCountry(
                  get(customer, "customer.phoneNumber")
                )
              }}
            </div>
            <div class="mr-2">
              Last updated
              {{
                $filters.dateTime.relativeTime(
                  get(customer, "customer.updatedAt")
                )
              }}
            </div>
            <div class="">{{ get(customer, "customer.numBuys") }} Buys</div>
          </v-list-item-subtitle>

          <v-tooltip v-if="get(customer, 'customer.flagged')" bottom>
            <template #activator="{ on, attrs }">
              <v-list-item-subtitle
                class="error--text mt-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  size="1em"
                  class="error--text mr-2"
                  v-bind="attrs"
                  v-on="on"
                  >$vuetify.icons.flag</v-icon
                >
                {{ get(customer, "customer.flagReason") }}
              </v-list-item-subtitle>
            </template>
            <template>{{ get(customer, "customer.flagReason") }}</template>
          </v-tooltip>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>
